import React from 'react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function setSelected(days, date, selected) {
    if (numberOfSelected(days) >= 2) {
        return days.map((day) => (
            {
                date: day.date,
                isSelected: (day.date === date) ? true : day.lastSelected,
                lastSelected: day.date === date
            }
        ))
    } else {
        return days.map((day) => (
            {
                date: day.date,
                isSelected: day.date === date ? selected : day.isSelected,
                lastSelected: day.date === date
            }
        ))
    }

}

function numberOfSelected(days) {
    return days.reduce((prev, curr) => {
        return curr.isSelected ? prev + 1 : prev
    }, 0)
}

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: [
                { date: '2022-01-01', isSelected: false, lastSelected: false },
                { date: '2022-01-02', isSelected: false, lastSelected: false },
                { date: '2022-01-03', isSelected: false, lastSelected: false },
                { date: '2022-01-04', isSelected: false, lastSelected: false },
                { date: '2022-01-05', isSelected: false, lastSelected: false },
                { date: '2022-01-06', isSelected: false, lastSelected: false },
                { date: '2022-01-07', isSelected: false, lastSelected: false },
                { date: '2022-01-08', isSelected: false, lastSelected: false },
                { date: '2022-01-09', isSelected: false, lastSelected: false },
                { date: '2022-01-10', isSelected: false, lastSelected: false },
                { date: '2022-01-11', isSelected: false, lastSelected: false },
                { date: '2022-01-12', isSelected: false, lastSelected: false },
                { date: '2022-01-13', isSelected: false, lastSelected: false },
                { date: '2022-01-14', isSelected: false, lastSelected: false },
            ]
        }
    }

    handleChange = (e) => {
        this.setState({ days: setSelected([...this.state.days], e.target.dateTime, true) }, () => {
            let ind = 0
            this.state.days.forEach((day) => {
                if (day.isSelected && ind === 0) {
                    this.props.handleChange(
                        {
                            target: {
                                id: "startDate",
                                value: day.date
                            }
                        }
                    )
                    ind++
                }
                if (day.isSelected && ind === 1) {
                    this.props.handleChange(
                        {
                            target: {
                                id: "endDate",
                                value: day.date
                            }
                        }
                    )
                }
            })
        })
    };

    render() {
        return (
            <div>
                <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                    <div>S</div>
                </div>
                <div className="mt-2 grid grid-cols-7 text-sm">
                    {this.state.days.map((day, dayIdx) => (
                        <div key={day.date} className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
                            <button
                                onClick={this.handleChange}
                                type="button"
                                className={classNames(
                                    day.isSelected && 'text-white',
                                    !day.isSelected && 'text-indigo-600',
                                    !day.isSelected && 'text-gray-900',
                                    day.isSelected && 'bg-gray-900',
                                    !day.isSelected && 'hover:bg-gray-200',
                                    'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                                )}
                            >
                                <time dateTime={day.date}>{day.date.split('-').pop().replace(/^0/, '')}</time>
                            </button>
                        </div>
                    ))}
                </div>
            </div >
        );
    }
}

export default Calendar;
