import React from 'react';
import './App.css'
import moment from 'moment';

function TimeOutput(props) {
    return (
        <form className="space-y-8">
            <div className="sm:grid sm:grid-cols-2 sm:gap-16 sm:items-start sm:border-t sm:border-gray-400 sm:pt-5">
                <div className="mt-1 sm:mt-0 sm:col-span-1 md:text-right text-5xl">
                    {Math.trunc(moment.duration(props.duration).asHours())}
                </div>
                <label htmlFor="country" className=" md:text-left block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-3xl">
                    hours
                </label>
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:gap-16 sm:items-start  sm:pt-5">
                <div className="mt-1 sm:mt-0 sm:col-span-1 md:text-right text-5xl">
                    {moment.duration(props.duration).minutes()}
                </div>
                <label htmlFor="country" className=" md:text-left block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-3xl">
                    minutes
                </label>
            </div>
        </form>
    );
}

export default TimeOutput;
