import './App.css'
import Dates from './Dates';
import MainCard from './MainCard';
import TimeOutput from './TimeOutput';
import React from 'react';
import moment from 'moment';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      difference: 0,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.setState({ difference: this.computeDifference(this.state) });
    });
  };
  

  computeDifference = (state) => {
    const start = (state.startDate === state.endDate)? moment(state.startTime, "hh:mm"): moment(state.startDate + state.startTime, "YYYY-MM-DDhh:mm")
    const end = (state.startDate === state.endDate)? moment(state.endTime, "hh:mm"): moment(state.endDate + state.endTime, "YYYY-MM-DDhh:mm")
    return end.isAfter(start) ? moment.duration(end.diff(start)) : 0
  }

  render() {
    return (
      <div className="App">
        <h1 className="text-5xl text-center  pt-12 mb-16">
          Jenny Calculates Reaction Times
        </h1>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <div className="grid md:grid-cols-2 gap-6">
              <MainCard title="Enter start / end time">
                <Dates handleChange={this.handleChange} computeDifference={this.computeDifference} state={this.state} />
              </MainCard>
              <MainCard title="Your reaction took">
                <TimeOutput duration={this.state.difference} />
              </MainCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
