import './App.css'

function MainCard(props) {
    return (
        <div className="w-full p-6 mb-6 rounded-md border border-gray-400 bg-stone-50" >
            <h2 className="text-3xl mb-6">
                {props.title}
            </h2>
            {props.children}
        </div>
    );
}

export default MainCard;
