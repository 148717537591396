import React from 'react';
import Calendar from './Calendar';

function Dates(props) {
    return (
        <form className="space-y-8 ">

            <div className="sm:border-t sm:border-gray-400 sm:pt-5 ">
                <Calendar handleChange={props.handleChange} />
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 ">
                <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Start Time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                        id="startTime"
                        type="time"
                        value={props.state.startTime}
                        onChange={props.handleChange}
                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                    </input>
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    End Time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                        id="endTime"
                        type="time"
                        value={props.state.endTime}
                        onChange={props.handleChange}
                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                    </input>
                </div>
            </div>
        </form>
    );
}

export default Dates;
